import { useState } from "react";
import styled from "styled-components";
import stylingVariables from '../../../src/style/Variables.module.scss';

interface Props {
  title?: string;
  children?: React.ReactNode;
}

const ExpandButton = styled.button`
  background-color: ${stylingVariables.themeBgColorExpandButton};
  cursor: pointer;
  border: 0;
  font-size: 1rem;
`;

const AttachmentFieldset = styled.fieldset`
  border-radius: 5px;
  border: 2px solid #aaa;
  min-height: 11rem;
  z-index: 100;
`;

interface ExpandableDivProps {
  expanded?: boolean;
}

const ExpandableDiv = styled.div<ExpandableDivProps>`
  height: ${(props) => (props.expanded ? "auto" : "8rem")};
  overflow: ${(props) => (props.expanded ? "auto" : "hidden")};
  -webkit-mask-image: ${(props) =>
    props.expanded
      ? ""
      : "-webkit-gradient(linear,left top,left bottom,from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0.2)))"};
`;

const AttachmentContainer: React.FC<Props> = ({ title, children }) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <AttachmentFieldset>
      <legend>
        <ExpandButton
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          {title}
        </ExpandButton>
      </legend>
      <ExpandableDiv expanded={expanded}>{children}</ExpandableDiv>
    </AttachmentFieldset>
  );
};

export default AttachmentContainer;
