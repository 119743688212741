import styled from "styled-components";

const ErrorWrapper = styled.div`
  margin: 20px;
`;

const Error: React.FC = () => {
  return <ErrorWrapper>Unauthorized Client Host</ErrorWrapper>;
};
export default Error;
