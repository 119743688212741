import axios from "axios";

export const sendAccessLog = async (journalIds: string[]) => {
  if(journalIds && journalIds.length > 0) {
    try {
        const resp = axios.post('/api/accessLog', {
          journalIds
        });
        console.debug((await resp).data);
    } catch (err) {
        console.error(err);
    }
  } else {
    console.debug("Empty journalId list. No accessLog data sent");
  }
};
