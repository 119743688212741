import React, { useState } from "react";
import "./PdfViewer.scss";
import { pdfjs, Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Attachments } from "../../types/medicalData";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../App";

interface Props {
  pdfFileBase64?: Attachments;
  scale?: number;
}

const PdfViewer: React.FC<Props> = ({ pdfFileBase64, scale = 1.25 }) => {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState<number>(0);
  const zoomLevel = useSelector(
    (state: RootState) => state.medicalDataState.zoomLevel
  );

  const onDocumentLoadSuccess = (pdf: pdfjs.PDFDocumentProxy) => {
    setNumPages(pdf._pdfInfo.numPages);
  };

  return (
    <div className="pdf-container">
      {pdfFileBase64 ? (
        <>
          <Document
            file={`data:${pdfFileBase64.contentType};base64,${pdfFileBase64.data}`}
            onLoadSuccess={onDocumentLoadSuccess.bind(this)}
            onLoadError={(error) => {
              console.log(error);
            }}
            error={t("attachments.error")}
          >
            <ul>
              {Array.from(new Array(numPages), (el, index) => (
                <li key={`page_li_${index + 1}`}>
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    loading={t("attachments.loadingPage", {
                      current: index + 1,
                      total: numPages,
                    })}
                    scale={zoomLevel}
                  />
                  <p key={`page_number_${index + 1}`}>
                    {t("attachments.pages", {
                      current: index + 1,
                      total: numPages,
                    })}
                  </p>
                </li>
              ))}
            </ul>
          </Document>
        </>
      ) : (
        <>{t("attachments.loading")}</>
      )}
    </div>
  );
};

export default PdfViewer;
