import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../App";
import { medicalDataState } from "../../hooks/useDefault/medicalDataState";

const Slider = styled.input`
  display: inline-block;
  vertical-align: middle;
`;

const SliderLabel = styled.label`
  font-weight: 400 !important;
  display: inline-block;
  vertical-align: middle;
`;

const Container = styled.div`
  padding: 20px 5px;
  min-width: 150px;
  display: table-cell;
`;

const ZoomDocument: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const zoomLevel = useSelector(
    (state: RootState) => state.medicalDataState.zoomLevel
  );

  const [scale, setScale] = useState<number>(zoomLevel);

  return (
    <Container>
      <SliderLabel>{t(`attachments.zoom`)}:</SliderLabel>
      <Slider
        type="range"
        min="0.1"
        max="3"
        value={scale}
        step={0.1}
        onChange={(e) => {
          setScale(Number(e.target.value));
          setTimeout(() => {
            dispatch(
              medicalDataState.actions.setZoom({ zoom: Number(e.target.value) })
            );
          }, 500);
        }}
      />
    </Container>
  );
};
export default ZoomDocument;
