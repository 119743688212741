import styled from "styled-components";

const MsgWrapper = styled.div`
  margin: 20px;
`;

const Hello: React.FC = () => {
  return <MsgWrapper>Hello good sir/ma'am</MsgWrapper>;
};
export default Hello;
