import styled from "styled-components";

interface SpinnerProps {
  size: string;
  borderWidth: string;
}

const SpinnerSpan = styled.span<SpinnerProps>`
  display: block;
  margin-left: auto;
  margin-right: auto;
  grid-column-start: 1;
  grid-column-end: 6;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;

  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: ${(props) => `${props.borderWidth} solid #aaa`};
    animation: prixClipFix 2s linear infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes prixClipFix {
    0% {
      clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    25% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    50% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
    75% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }
    100% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
  }
`;

interface Props {
  size?: "small" | "big";
}

const Loader: React.FC<Props> = ({ size }) => {
  const width = size === "big" ? "96px" : "46px";
  const border = size === "big" ? "10px" : "5px";
  return <SpinnerSpan size={width} borderWidth={border}></SpinnerSpan>;
};

export default Loader;
