import i18n from "i18next";
import ICU from "i18next-icu";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import i18next from "i18next";

i18next.on("languageChanged", (lngs) => {
  document.documentElement.setAttribute("lang", lngs);
});

i18n
  .use(ICU)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "sv",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          navigation: {
            journal: "Journal",
            medication: "Medication",
            labList: "Lab list",
            warnings: "Warnings",
            other: "Other",
          },
          attachments: {
            files: "Files",
            noFiles: "No files of this type exists for this patient",
            pages: "Page {current} of {total}",
            loadingPage: "Loading page {current} of {total}...",
            loading: "Loading file...",
            error: "Error: failed to read file",
            settings: "Settings",
            zoom: "Zoom PDF",
          },
          links: { goToTop: "Go to top" },
          systemMessages: {
            genericRequestErrorHeader: "Error {code}",
            genericRequestError: "Something went wrong!",
            unknownError: "Unknown error",
            badRequest: "Bad request",
            unauthorized: "Unauthorized request",
            forbidden: "Forbidden request",
            notFound: "Patient not found",
            requestTimeout: "Request timeout",
          },
        },
      },
      sv: {
        translation: {
          navigation: {
            journal: "Journal",
            medication: "Läkemedel",
            labList: "Lablista",
            warnings: "Varningar",
            other: "Övrigt",
          },
          attachments: {
            files: "Filer",
            noFiles:
              "Inga filer av den här typen hittades för den här patienten",
            pages: "Sida {current} av {total}",
            loadingPage: "Läser sida {current} av {total}...",
            loading: "Laddar fil...",
            error: "Fel: misslyckades att läsa in filen",
            settings: "Inställningar",
            zoom: "Zoom PDF",
          },
          links: { goToTop: "Gå till toppen" },
          systemMessages: {
            genericRequestErrorHeader: "Fel {code}",
            genericRequestError: "Någonting blev fel!",
            unknownError: "Okänt fel",
            badRequest: "Felaktig anrop",
            unauthorized: "Otillåtet anrop",
            forbidden: "Förbjudet anrop",
            notFound: "Patienten hittades inte",
            requestTimeout: "Tidsgräns uppnådd",
          },
        },
      },
      no: {
        translation: {
          navigation: {
            journal: "Journal",
            medication: "Legemiddel",
            labList: "Lablista",
            warnings: "Varsler",
            other: "Annen",
          },
          attachments: {
            files: "Filer",
            noFiles:
              "Ingen filer av denne typen ble funnet for denne pasienten",
            pages: "Side {current} av {total}",
            loadingPage: "Laster side {current} av {total}...",
            loading: "Laster fil...",
            error: "Feil: kunne ikke laste filen",
            settings: "Innstillinger",
            zoom: "Zoom PDF",
          },
          links: { goToTop: "Gå til toppen" },
          systemMessages: {
            genericRequestErrorHeader: "Feil {code}",
            genericRequestError: "Noe gikk galt",
            unknownError: "Ukjent feil",
            badRequest: "Feil anrop",
            unauthorized: "Uautorisert forespørsel",
            forbidden: "Forbudt forespørsel",
            notFound: "Pasienten ble ikke funnet",
            requestTimeout: "Tidsgrense nådd",
          },
        },
      },
    },
  });
export default i18n;
