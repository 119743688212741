// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: block;
  margin: 20px;
  grid-column-start: 1;
  grid-column-end: 6;
}
.alert hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.alert.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert.alert-info hr {
  border-top-color: #abdde5;
}
.alert.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert time {
  font-weight: 700;
  margin-right: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Message/Message.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,wBAAA;EACA,6BAAA;EACA,sBAAA;EACA,cAAA;EAEA,YAAA;EACA,oBAAA;EACA,kBAAA;AAAF;AAEE;EACE,gBAAA;EACA,mBAAA;EACA,SAAA;EACA,wCAAA;AAAJ;AAGE;EACE,cAAA;EACA,yBAAA;EACA,qBAAA;AADJ;AAGI;EACE,yBAAA;AADN;AAKE;EACE,cAAA;EACA,yBAAA;EACA,qBAAA;AAHJ;AAKI;EACE,yBAAA;AAHN;AAOE;EACE,cAAA;EACA,yBAAA;EACA,qBAAA;AALJ;AAOI;EACE,yBAAA;AALN;AASE;EACE,gBAAA;EACA,oBAAA;AAPJ","sourcesContent":[".alert {\n  position: relative;\n  padding: 0.75rem 1.25rem;\n  border: 1px solid transparent;\n  border-radius: 0.25rem;\n  display: block;\n\n  margin: 20px;\n  grid-column-start: 1;\n  grid-column-end: 6;\n\n  hr {\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n    border: 0;\n    border-top: 1px solid rgba(0, 0, 0, 0.1);\n  }\n\n  &.alert-info {\n    color: #0c5460;\n    background-color: #d1ecf1;\n    border-color: #bee5eb;\n\n    hr {\n      border-top-color: #abdde5;\n    }\n  }\n\n  &.alert-danger {\n    color: #721c24;\n    background-color: #f8d7da;\n    border-color: #f5c6cb;\n\n    hr {\n      border-top-color: #f1b0b7;\n    }\n  }\n\n  &.alert-warning {\n    color: #856404;\n    background-color: #fff3cd;\n    border-color: #ffeeba;\n\n    hr {\n      border-top-color: #ffe8a1;\n    }\n  }\n\n  time {\n    font-weight: 700;\n    margin-right: 0.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
