import { combineReducers, configureStore } from "@reduxjs/toolkit";
import axios from "axios";
import { Provider } from "react-redux";
import "./App.scss";
import AppRouter from "./AppRouter";
import CountryLocale from "./hooks/useDefault/localState";
import { medicalDataState } from "./hooks/useDefault/medicalDataState";
import { setupInterceptorsTo } from "./interceptors";

const reducer = combineReducers({
  medicalDataState: medicalDataState.reducer,
  localeState: CountryLocale.reducer,
});

export const store = configureStore({ reducer });
export type RootState = ReturnType<typeof store.getState>;

setupInterceptorsTo(axios);

const App = () => {
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
};

export default App;
