export interface Locale {
  IanaZone: string;
  LocaleLang: string;
  i18nCode: string;
}

const countryCodes: Locale[] = [
  {
    IanaZone: "Europe/Stockholm",
    LocaleLang: "sv-SE",
    i18nCode: "sv",
  },
  {
    IanaZone: "Europe/London",
    LocaleLang: "en-GB",
    i18nCode: "en",
  },

  {
    IanaZone: "Europe/Oslo",
    LocaleLang: "nb-NO",
    i18nCode: "no",
  },
];

export const getLocaleFromCountryCode = (code: string): Locale => {
  const l = countryCodes.filter((cc) => {
    return cc.LocaleLang === code || cc.i18nCode === code;
  });
  if (l.length > 0) {
    return l[0];
  }
  return countryCodes[0];
};

export { countryCodes };
