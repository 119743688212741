import axios from "axios";
import { MedicalData } from "../types/medicalData";

export const getMedicalData = async (id: string, clinicId: string): Promise<MedicalData> => {
  const { data } = await axios({
    method: "get",
    url: `/api/MedicalData/$records?id=${id}&clinicId=${clinicId}`,
    timeout: 120000,
    headers: {
      accept: "application/fhir+json",
    },
  });

  return data;
};
