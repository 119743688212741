import { DateTime } from "luxon";

export const getTitleAndDate = (title: string, creation: string): string => {
  var luxonDate = DateTime.fromISO(creation);
  return `${title} (${luxonDate.toLocaleString()})`;
};

export const getBase64ImageString = (
  contentType: string,
  data: any
): string => {
  return `data:${contentType.substring(0, contentType.lastIndexOf("+"))};base64,${data}`;
};
