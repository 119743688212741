import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import NewWindow from "react-new-window";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../App";
import { getBase64ImageString, getTitleAndDate } from "../../common/utils";
import { Attachments } from "../../types/medicalData";
import AttachmentContainer from "../AttachmentContainer/AttachmentContainer";
import PdfViewer from "../PdfViewer/PdfViewer";
import { sendAccessLog } from "../../api/AccessLogClient";

interface Props {
  attachments?: Attachments[];
  tabName: string;
}

const AttachmentSection = styled.section`
  background-color: inherit;
  padding: 20px;
  grid-column-start: 2;
  grid-column-end: 6;
`;

const HiddenAnchor = styled.p`
  float: left;
  visibility: hidden;
  opacity: 0;
`;

const ImgDocuments = styled.img`
  display: block;
`;

const AttachmentContainerList: React.FC<Props> = ({ attachments, tabName }) => {
  const { t } = useTranslation();
  const [selectedImg, setSelectedImg] = useState<Attachments | undefined>();
  const zoomLevel = useSelector(
    (state: RootState) => state.medicalDataState.zoomLevel
  );

  useEffect(() => {
    function extractJournalIds(attachments: Array<Attachments>): Array<string> {
      return attachments.map(attachment => attachment.extension[0].valueId);
    }

    // Create access log for the attachments that are being loaded
    sendAccessLog(extractJournalIds(attachments? attachments: new Array<Attachments>()));
  }, [attachments]);

  return (
    <AttachmentSection className="attachement-container-list">
      {attachments?.map((attachment, i) => (
        <article key={`container-${tabName}-${i}`}>
          <HiddenAnchor id={`document-${i}`}></HiddenAnchor>
          <AttachmentContainer
            title={getTitleAndDate(attachment.title, attachment.creation)}
          >
            {attachment.contentType.includes("application/pdf") ? (
              <PdfViewer pdfFileBase64={attachment} scale={zoomLevel} />
            ) : (
              /*<a
                href={`#document-${i}`}
                onClick={() => {
                  // The "setSelectedImg(attachment)" causes compile error with error message "Permission denied to access property 'outerWidth'
                  // on cross-origin object" when clicked via iFrame, so we'll skip clickable images for now and maybe look into it more in the future:
                  setSelectedImg(attachment);
                }}
              >*/
                <ImgDocuments
                  src={getBase64ImageString(
                    attachment.contentType,
                    attachment.data
                  )}
                  alt={getTitleAndDate(attachment.title, attachment.creation)}
                />
              /*</a>*/
            )}
            <a href={"#document-0"}>{t("links.goToTop")}</a>
          </AttachmentContainer>
        </article>
      ))}

      {selectedImg && (
        <NewWindow
          name="ImageWindow"
          onUnload={() => {
            setSelectedImg(undefined);
          }}
          features={{ height: 1000, width: 1000 }}
          title={getTitleAndDate(selectedImg.title, selectedImg.creation)}
          copyStyles={false}
        >
          <img
            src={getBase64ImageString(
              selectedImg.contentType,
              selectedImg.data
            )}
            alt={getTitleAndDate(selectedImg.title, selectedImg.creation)}
            style={{ width: "100%" }}
          />
        </NewWindow>
      )}
    </AttachmentSection>
  );
};

export default AttachmentContainerList;
