import { DateTime } from "luxon";
import { Trans, useTranslation } from "react-i18next";
import "./Message.scss";

interface AlertMessage {
  level: "info" | "danger" | "warning";
  children?: React.ReactNode;
}

export interface ErrorMessageProps {
  status?: number;
  statusText?: string;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  status,
  statusText,
}) => {
  const { t } = useTranslation();

  const getErrorMessage = (): string => {
    switch (status) {
      case 400:
        return "systemMessages.badRequest";
      case 401:
        return "systemMessages.unauthorized";
      case 403:
        return "systemMessages.forbidden";
      case 404:
        return "systemMessages.notFound";
      case 408:
        return "systemMessages.requestTimeout";
      default:
        return "systemMessages.unknownError";
    }
  };

  return (
    <Message level="danger">
      <div>
        <h4>
          {t("systemMessages.genericRequestErrorHeader", {
            code: status,
          })}
        </h4>
        <Trans i18nKey={getErrorMessage()} />
        <hr />
        <small>
          <time dateTime={DateTime.now().toLocaleString()}>
            {DateTime.now().toLocaleString()}{" "}
            {DateTime.now().toFormat("HH:mm:ss")}
          </time>
          {status && `${status}: ${statusText}`}
        </small>
      </div>
    </Message>
  );
};

const Message: React.FC<AlertMessage> = ({ level, children }) => {
  return (
    <div className={`alert alert-${level}`} role="alert">
      {children}
    </div>
  );
};
export default Message;
