import { createSlice } from "@reduxjs/toolkit";
import {
  Locale,
  countryCodes,
  getLocaleFromCountryCode,
} from "../../types/countryCodes";

export interface IState {
  locale: Locale;
}

const localeFromStorage = localStorage.getItem("selectedLocale");

const defaultLocale =
  countryCodes.find((l) => l.LocaleLang === localeFromStorage) ||
  countryCodes.find((l) => l.LocaleLang === navigator.language) ||
  countryCodes.find((l) => l.LocaleLang === "sv-SE")!;

const initialState: IState = {
  locale: defaultLocale,
};

export const CountryLocale = createSlice({
  name: "countryLocale",
  initialState,
  reducers: {
    setSelectedLocale: (state, action) => {
      const { locale } = action.payload;
      const selectedCountry = getLocaleFromCountryCode(locale);
      state.locale = selectedCountry;
    },
  },
});

export default CountryLocale;
