import { AxiosError } from "axios";
import { Settings } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { getMedicalData } from "../../api/medicalData";
import { RootState } from "../../App";
import AttachmentContainerFileList from "../../components/AttachmentContainerFileList/AttachmentContainerFileList";
import AttachmentContainerList from "../../components/AttachmentContainerList/AttachmentContainerList";
import Loader from "../../components/Loader/Loader";
import {
  ErrorMessage,
  ErrorMessageProps,
} from "../../components/Message/Message";
import CountryLocale from "../../hooks/useDefault/localState";
import { medicalDataState } from "../../hooks/useDefault/medicalDataState";
import i18n from "../../i18n";
import { Attachments, MedicalData } from "../../types/medicalData";

const MainContainer = styled.main`
  padding-top: 7rem;
  display: grid;
  grid-gap: 1rem;
`;

const Main: React.FC = () => {
  const dispatch = useDispatch();
  const { id, clinicId } = useParams<{ id: string, clinicId: string }>();

  const locale = useSelector((state: RootState) => state.localeState.locale);
  Settings.defaultZone = locale.IanaZone;
  Settings.defaultLocale = locale.LocaleLang;

  const medicalData = useSelector(
    (state: RootState) => state.medicalDataState.allMedicalData
  );
  const journalData = useSelector(
    (state: RootState) => state.medicalDataState.journals
  );
  const medicationData = useSelector(
    (state: RootState) => state.medicalDataState.medications
  );
  const labData = useSelector(
    (state: RootState) => state.medicalDataState.labResults
  );
  const warningData = useSelector(
    (state: RootState) => state.medicalDataState.warnings
  );
  const documentData = useSelector(
    (state: RootState) => state.medicalDataState.otherDocuments
  );

  const [loading, setLoading] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const tabName = query.get("tab");
  const languageCode = query.get("language");

  const [error, setError] = useState<ErrorMessageProps | undefined>(undefined);

  if (languageCode) {
    dispatch(
      CountryLocale.actions.setSelectedLocale({
        locale: languageCode,
      })
    );
  }

  useEffect(() => {
    i18n.changeLanguage(locale.i18nCode);
    localStorage.setItem("selectedLocale", locale.LocaleLang);
  }, [locale]);

  const getAttachments = (): Attachments[] | undefined => {
    switch (tabName) {
      case "medication":
        return medicationData;
      case "lab":
        return labData;
      case "warning":
        return warningData;
      case "documents":
        return documentData;
      default:
        return journalData;
    }
  };

  useEffect(() => {
    if (id && clinicId && !medicalData) {
      setLoading(true);
      getMedicalData(id, clinicId)
        .then((response: MedicalData) => {
          dispatch(medicalDataState.actions.setMedicalData({ data: response }));
          setError(undefined);
        })
        .catch((error: AxiosError) => {
          setError({
            status: error.response?.status,
            statusText: error.response?.statusText,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [medicalData, id, clinicId, dispatch]);

  const attachments = getAttachments();

  return (
    <MainContainer>
      {loading && <Loader size={"big"} />}
      {error && (
        <ErrorMessage status={error?.status} statusText={error?.statusText} />
      )}
      {!loading && attachments && (
        <>
          <AttachmentContainerFileList attachments={attachments} />
          <AttachmentContainerList 
            attachments={attachments}
            tabName={tabName ? tabName : "journal"}
          />
        </>
      )}
    </MainContainer>
  );
};
export default Main;
