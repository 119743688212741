import { useTranslation } from "react-i18next";
import { Attachments } from "../../types/medicalData";
import styled from "styled-components";
import { getTitleAndDate } from "../../common/utils";
import ZoomDocument from "../ZoomDocument/ZoomDocument";
import { useEffect, useState } from "react";

interface Props {
  attachments?: Attachments[];
}

const FileNav = styled.nav`
  background-color: inherit;
  padding: 20px;
  padding-top: 30px;
  grid-column-start: 1;
  grid-column-end: 2;
`;

const Title = styled.p`
  font-weight: 700;
  text-align: center;
`;

const Container = styled.div`
  background: #f9f9f9;
  border: 1px solid #aaa;
  border-radius: 5px;
  font-size: 0.9rem;
  margin-bottom: 1em;
  padding: 1rem;
  max-width: 24rem;
  position: sticky;
  top: 8.875rem;
  padding-top: 0;
`;

const FileList = styled.ul`
  list-style: none;
  list-style: outside none none !important;
  padding: 0;
`;

const FileListItem = styled.li`
  list-style: none;
  list-style: outside none none !important;
  padding: 0;
  margin: 0.5rem 0;
  &:last-child {
    font-weight: 900;
    margin-top: 2rem;
  }
`;

interface SettingsButtonProps {
  expanded?: boolean;
}

const SettingsButton = styled.button<SettingsButtonProps>`
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  min-width: 150px;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  font-weight: 800;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: ${(props) => (props.expanded ? "0" : "5px")};
  border-bottom-right-radius: ${(props) => (props.expanded ? "0" : "5px")};
  &:hover {
    background-color: #ccc;
  }
`;

const SettingsButtonSymbol = styled.span`
  font-size: 13px;
  color: #444;
  float: right;
  margin-left: 5px;
  font-weight: 800;
`;

const SettingsContent = styled.div`
  display: block;
  overflow: hidden;
  background-color: #f1f1f1;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const AttachmentContainerFileList: React.FC<Props> = ({ attachments }) => {
  const { t } = useTranslation();

  const [settingsExpanded, setSettingsExpanded] = useState(false);

  const showSettings = (): boolean => {
    if (attachments) {
      const t = attachments.find((a) =>
        a.contentType.includes("application/pdf")
      );
      return t !== undefined;
    }
    return false;
  };

  useEffect(() => {
    setSettingsExpanded(false);
  }, [attachments]);

  return (
    <FileNav>
      <Container>
        <Title>
          {t(
            `attachments.${
              attachments && attachments.length > 0 ? "files" : "noFiles"
            }`
          )}
        </Title>
        {attachments && attachments.length > 0 && (
          <FileList>
            {attachments?.map((attachment, i) => (
              <FileListItem key={`li-link-${i}`}>
                <a href={`#document-${i}`}>
                  {getTitleAndDate(attachment.title, attachment.creation)}
                </a>
              </FileListItem>
            ))}
            <FileListItem>
              {showSettings() && (
                <FileList>
                  <FileListItem>
                    <SettingsButton
                      expanded={settingsExpanded}
                      onClick={() => setSettingsExpanded(!settingsExpanded)}
                    >
                      {t(`attachments.settings`)}
                      <SettingsButtonSymbol>
                        {settingsExpanded ? "-" : "+"}
                      </SettingsButtonSymbol>
                    </SettingsButton>
                    {settingsExpanded && (
                      <SettingsContent>
                        <ZoomDocument />
                      </SettingsContent>
                    )}
                  </FileListItem>
                </FileList>
              )}
            </FileListItem>
            <FileListItem></FileListItem>
            <FileListItem>
              <hr />
              <a href={"#document-0"}>{t("links.goToTop")}</a>
            </FileListItem>
          </FileList>
        )}
      </Container>
    </FileNav>
  );
};
export default AttachmentContainerFileList;
